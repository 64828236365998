import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import Button from "react-bootstrap/Button"
import React from "react"
import Logo from "../images/logobasico.png"

const style={
    color:"var(--secondary)",
    backgroundColor: "var(--primary)"
}
const NavigationBar =() =>{
    const {t}= useTranslation()
    const {language, originalPath} = useI18next();
    console.log(language)
    return(
    <Navbar variant="dark" expand="lg" style={style}>
        <Navbar.Brand>
            <img src={Logo} className="mainLogo"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
            <Link className="nav-link" to="/about">{t("deals")}</Link>
            <Link className="nav-link" to="/">{t("catalog")}</Link>
            <Link className="nav-link" to="/">{t("cart")}</Link>
            <Link className="nav-link" to="/">{t("contact")}</Link>
            <Link className="nav-link" to={originalPath} language={language==="es"? "en" : "es"} to="/">{language==="es"? "English" : "Español"}</Link>
            <Form inline style={{marginLeft:"1em"}}>
                <FormControl type="text" placeholder={t("search")} className="mr-sm-2" />
                <Button style={{backgroundColor: "var(--quinary)"}}>{t("search")}</Button>
            </Form>
            </Nav>
        </Navbar.Collapse>
    </Navbar>
)}

export default NavigationBar