import {Link, Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { FiMap } from "@react-icons/all-files/fi/FiMap";
import {HiOutlineMail} from "@react-icons/all-files/hi/HiOutlineMail";
import {AiFillPhone} from "@react-icons/all-files/ai/AiFillPhone";
import {IoLogoWhatsapp} from "@react-icons/all-files/io/IoLogoWhatsapp"
import {AiFillAmazonCircle} from "@react-icons/all-files/ai/AiFillAmazonCircle"
import {FaEbay} from "@react-icons/all-files/fa/FaEbay"

const Footer =() =>{
    const {t}= useTranslation()
    return(
        <div className="footer">
            <Row>
                <Col md={6} sm={12} style={{textAlign:"left"}}>
                    <div style={{marginLeft: "6em"}}>
                        <h4>Design and Solution</h4>
                        <h5><AiFillPhone/> (1) 234 567 897</h5>
                        <h5><FiMap/> Imaginary Address, Palmdale, California</h5>
                        <h5><HiOutlineMail /> imaginary@email.com</h5>
                    </div>
                </Col>
                <Col md={6} sm={12}>
                    <h4>Follow us in other platforms!</h4>
                    <div className="rrss">
                        <a className="socialLogo"><IoLogoWhatsapp /></a>
                        <a className="socialLogo"><AiFillAmazonCircle /></a>
                        <a className="socialLogo"><FaEbay/></a>
                    </div>
                </Col>
            </Row>
            
        </div>
    )
}
export default Footer